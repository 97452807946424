import { ProviderService } from 'shared/models/providerService.model';
import { Ref } from 'vue';

export abstract class BaseCompareProductsService extends ProviderService {
  protected static readonly serviceName = 'compareProductsService';

  public abstract count: Ref<number>;

  public abstract addToCompare<T, R>(item: T): Promise<R>;

  public abstract deleteFromCompare<T>(item: T): Promise<void>;

  public abstract toggleModal(): void;

  public abstract loadCount(): Promise<void>;
}
